export * from './v1'
export * from './v2'

import { setV1BaseUrl } from './v1'
import { setV2BaseUrl } from './v2'

export const setBaseUrl = (apiBaseUrl) => {
  setV1BaseUrl(apiBaseUrl)
  setV2BaseUrl(apiBaseUrl + '/v2')
}
