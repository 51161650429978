import { LOGIN_REQUIRED } from '../middlewares/auth'
import constantCreator from '../libs/constantCreator'
import { trackingShareProperty } from '../beacon/beacon'
import * as ApiResponseCode from '../libs/ApiResponseCode'

import { v1PostWithToken, v1GetWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('place')

export const INIT_PLACE = createConst('INIT_PLACE')
export const GET_PLACE = createConst('GET_PLACE')
export const GET_PLACE_SUCCESS = createConst('GET_PLACE_SUCCESS')
export const GET_PLACE_FAILED = createConst('GET_PLACE_FAILED')

export function initPlace(placeId) {
  return {
    type: INIT_PLACE,
    placeId,
  }
}

export const getPlace = ({ placeId }) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_PLACE })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/post/get', { postID: placeId }, userToken)
      dispatch({ type: GET_PLACE_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_PLACE_FAILED, error })
    }
  }
}

export const GET_PLACE_COMMENTS = createConst('GET_PLACE_COMMENTS')
export const GET_PLACE_COMMENTS_SUCCESS = createConst('GET_PLACE_COMMENTS_SUCCESS')
export const GET_PLACE_COMMENTS_FAILED = createConst('GET_PLACE_COMMENTS_FAILED')

export function getPlaceComments({ userId, placeId }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_PLACE_COMMENTS })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/reply/get', { postID: placeId }, userToken)
      dispatch({ type: GET_PLACE_COMMENTS_SUCCESS, response, userId })
    } catch (error) {
      dispatch({ type: GET_PLACE_COMMENTS_FAILED, error, userId })
    }
  }
}

export const EXPAND_PLACE_COMMENTS = createConst('EXPAND_PLACE_COMMENTS')
export function expandPlaceComments({ place }) {
  return {
    type: EXPAND_PLACE_COMMENTS,
    beacon: { properties: trackingShareProperty(place) },
  }
}

export const OPEN_COMMENT_POP = createConst('OPEN_COMMENT_POP')
export const CLOSE_COMMENT_POP = createConst('CLOSE_COMMENT_POP')
export const SUBMIT_COMMENT = createConst('SUBMIT_COMMENT')
export const SUBMIT_COMMENT_SUCCESS = createConst('SUBMIT_COMMENT_SUCCESS')
export const SUBMIT_COMMENT_FAILED = createConst('SUBMIT_COMMENT_FAILED')

export function openCommentPop({ commentId, via, place }) {
  return {
    [LOGIN_REQUIRED]: true,
    type: OPEN_COMMENT_POP,
    beacon: { properties: { ...trackingShareProperty(place), via } },
    commentId,
    via,
  }
}

export function closeCommentPop() {
  return { type: CLOSE_COMMENT_POP }
}

export function submitComment({ userId, text, clap, placeId, replyID, via, place }) {
  const body = {
    b: 0,
    postType: 'place',
    postID: placeId,
    text,
    clap,
  }
  if (replyID) body.replyID = replyID

  return async (dispatch, _getState, { i18n, toast }) => {
    const options = {
      [LOGIN_REQUIRED]: true,
      clap,
      beacon: {
        properties: {
          claps: clap,
          'review.text': text,
          'review.text.characters': text.length,
          via,
          ...trackingShareProperty(place),
        },
      },
    }
    dispatch({ type: SUBMIT_COMMENT, ...options })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken('/editor/reply', body, userToken)
      dispatch({ type: SUBMIT_COMMENT_SUCCESS, response, ...options })
      toast.success(i18n.t('評論送出成功'))

      await dispatch(getPlaceComments({ userId, placeId }))
    } catch (error) {
      dispatch({ type: SUBMIT_COMMENT_FAILED, error, ...options })
    }
  }
}

export const REPORT_PLACE_COMMENT = createConst('REPORT_PLACE_COMMENT')
export const REPORT_PLACE_COMMENT_SUCCESS = createConst('REPORT_PLACE_COMMENT_SUCCESS')
export const REPORT_PLACE_COMMENT_FAILED = createConst('REPORT_PLACE_COMMENT_FAILED')

export function reportPlaceComment({ comment, place }) {
  const { postID, replyID } = comment
  return async (dispatch, _getState, { alert }) => {
    const options = {
      beacon: {
        properties: { ...trackingShareProperty(place) },
      },
    }
    dispatch({ type: REPORT_PLACE_COMMENT, ...options })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/reply/report',
        {
          postID,
          replyID,
        },
        userToken,
      )
      dispatch({ type: REPORT_PLACE_COMMENT_SUCCESS, response, ...options })
      if (response.code === ApiResponseCode.REPORT_COMMENT_SUCCESS) {
        alert(response.msg)
      }
    } catch (error) {
      dispatch({ type: REPORT_PLACE_COMMENT_FAILED, error, ...options })
    }
  }
}

export const GET_PLACE_POSTS = createConst('GET_PLACE_POSTS')
export const GET_PLACE_POSTS_SUCCESS = createConst('GET_PLACE_POSTS_SUCCESS')
export const GET_PLACE_POSTS_FAILED = createConst('GET_PLACE_POSTS_FAILED')

export function getPlacePosts({ userId, placeId, page, score, sort }) {
  const clas = placeId.replace('place.', '')
  const body = {
    type: 'place',
    clas,
    page,
    score,
    urlParam: {
      sort,
    },
  }
  return async (dispatch, getState) => {
    dispatch({ type: GET_PLACE_POSTS, userId })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/list/post', body, userToken)
      dispatch({ type: GET_PLACE_POSTS_SUCCESS, response, userId })
    } catch (error) {
      dispatch({ type: GET_PLACE_POSTS_FAILED, error, userId })
    }
  }
}

export const LIKE_COMMENT = createConst('LIKE_COMMENT')
export const LIKE_COMMENT_SUCCESS = createConst('LIKE_COMMENT_SUCCESS')
export const LIKE_COMMENT_FAILED = createConst('LIKE_COMMENT_FAILED')

export function likeComment({ comment, like, place }) {
  const { postID, replyID } = comment
  return async (dispatch, getState) => {
    const options = {
      [LOGIN_REQUIRED]: true,
      beacon: {
        properties: { ...trackingShareProperty(place) },
      },
      comment,
      like,
    }
    dispatch({ type: LIKE_COMMENT, ...options })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/reply/like',
        {
          postID,
          replyID,
          like,
        },
        userToken,
      )
      dispatch({ type: LIKE_COMMENT_SUCCESS, response, ...options })
    } catch (error) {
      dispatch({ type: LIKE_COMMENT_FAILED, error, ...options })
    }
  }
}

export const GET_MAP_EVENT_LIST = createConst('GET_MAP_EVENT_LIST')
export const GET_MAP_EVENT_LIST_SUCCESS = createConst('GET_MAP_EVENT_LIST_SUCCESS')
export const GET_MAP_EVENT_LIST_FAILED = createConst('GET_MAP_EVENT_LIST_FAILED')
export function getMapEventList() {
  return async (dispatch, getState) => {
    dispatch({ type: GET_MAP_EVENT_LIST })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken('/map/event', undefined, userToken)
      dispatch({ type: GET_MAP_EVENT_LIST_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_MAP_EVENT_LIST_FAILED, error })
    }
  }
}

export const SEARCH_PLACE = createConst('SEARCH_PLACE')
export const SEARCH_PLACE_SUCCESS = createConst('SEARCH_PLACE_SUCCESS')
export const SEARCH_PLACE_FAILED = createConst('SEARCH_PLACE_FAILED')

export function searchPlace({
  area,
  priceMin,
  priceMax,
  category,
  name,
  orderby,
  lat,
  lng,
  limit,
  offset,
  isOnlyOpenStore,
  isFavorite,
  isCoupon,
  lat_max,
  lat_min,
  lng_max,
  lng_min,
  distance,
}) {
  return async (dispatch, getState) => {
    dispatch({ type: SEARCH_PLACE, offset })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken(
        '/map/place',
        {
          area,
          priceMin,
          priceMax,
          category,
          name,
          orderby,
          lat,
          lng,
          limit,
          offset,
          isOnlyOpenStore,
          isFavorite,
          isCoupon,
          lat_max,
          lat_min,
          lng_max,
          lng_min,
          distance,
        },
        userToken,
      )
      dispatch({ type: SEARCH_PLACE_SUCCESS, response, offset })
    } catch (error) {
      dispatch({ type: SEARCH_PLACE_FAILED, error, offset })
    }
  }
}

export const SELECT_COMMENT = createConst('SELECT_COMMENT')
export function selectComment({ comment }) {
  return {
    type: SELECT_COMMENT,
    comment,
  }
}

export const CLOSE_SELECTED_COMMENT_POP = createConst('CLOSE_SELECTED_COMMENT_POP')
export function closeSelectedCommentPop() {
  return { type: CLOSE_SELECTED_COMMENT_POP }
}

export const DELETE_COMMENT = createConst('DELETE_COMMENT')
export const DELETE_COMMENT_SUCCESS = createConst('DELETE_COMMENT_SUCCESS')
export const DELETE_COMMENT_FAILED = createConst('DELETE_COMMENT_FAILED')

export function deleteComment({ comment }) {
  const { id: commentId, postID, replyID } = comment
  const body = {
    postID,
    replyID,
  }
  const options = { [LOGIN_REQUIRED]: true, commentId }
  return async (dispatch, _getState, { i18n, toast }) => {
    dispatch({ type: DELETE_COMMENT, ...options })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken('/editor/reply/del', body, userToken)
      dispatch({ type: DELETE_COMMENT_SUCCESS, response, ...options })
      toast(i18n.t('刪除成功'))
    } catch (error) {
      dispatch({ type: DELETE_COMMENT_FAILED, error, ...options })
    }
  }
}

export const GET_GIFT = createConst('GET_GIFT')
export const GET_GIFT_SUCCESS = createConst('GET_GIFT_SUCCESS')
export const GET_GIFT_FAILED = createConst('GET_GIFT_FAILED')

export function getGift({ placeId }) {
  const body = {
    postID: placeId,
  }
  return async (dispatch, getState) => {
    dispatch({ type: GET_GIFT })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/gift/get', body, userToken)
      dispatch({ type: GET_GIFT_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_GIFT_FAILED, error })
    }
  }
}

export const GET_RECOMMEND_LIST = createConst('GET_RECOMMEND_LIST')
export const GET_RECOMMEND_LIST_SUCCESS = createConst('GET_RECOMMEND_LIST_SUCCESS')
export const GET_RECOMMEND_LIST_FAILED = createConst('GET_RECOMMEND_LIST_FAILED')

export function getRecommendList({ placeId, lat, lng, requestType = 'recommendPlaceIds' }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_RECOMMEND_LIST, requestType })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1GetWithToken(
        `/places/${placeId}/recommends`,
        { lat, lng },
        userToken,
      )
      dispatch({ type: GET_RECOMMEND_LIST_SUCCESS, response, requestType })
    } catch (error) {
      dispatch({ type: GET_RECOMMEND_LIST_FAILED, error, requestType })
    }
  }
}

export const GET_MAP_PROMOTE = createConst('GET_MAP_PROMOTE')
export const GET_MAP_PROMOTE_SUCCESS = createConst('GET_MAP_PROMOTE_SUCCESS')
export const GET_MAP_PROMOTE_FAILED = createConst('GET_MAP_PROMOTE_FAILED')

export function getMapPromote({ city }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_MAP_PROMOTE })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/map/promote', { city }, userToken)
      dispatch({ type: GET_MAP_PROMOTE_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_MAP_PROMOTE_FAILED, error })
    }
  }
}
