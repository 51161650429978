import constantCreator from '../libs/constantCreator'
import { timeout } from '../libs/timer'
import { fetchUserPermission, v1PostWithToken, v1GetWithToken, validateToken } from '../api'
import { getUserToken } from './selector'

const createConst = constantCreator('currentUser')

export const LOGIN = createConst('LOGIN')
export const LOGIN_SUCCESS = createConst('LOGIN_SUCCESS')
export const LOGIN_FAILED = createConst('LOGIN_FAILED')
export const login = ({ username, password }) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOGIN })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/member/login/',
        { username, password },
        userToken,
      )
      dispatch({ type: LOGIN_SUCCESS, response })
    } catch (error) {
      dispatch({ type: LOGIN_FAILED, error })
    }
  }
}

export const LOGOUT = createConst('LOGOUT')
export const logout = () => {
  return {
    type: LOGOUT,
  }
}

export const GUEST_LOGIN = createConst('GUEST_LOGIN')
export const GUEST_LOGIN_SUCCESS = createConst('GUEST_LOGIN_SUCCESS')
export const GUEST_LOGIN_FAILED = createConst('GUEST_LOGIN_FAILED')
export const guestLogin = () => {
  return async (dispatch) => {
    dispatch({ type: GUEST_LOGIN })

    try {
      const { data: response } = await v1GetWithToken('/login/guest')
      dispatch({ type: GUEST_LOGIN_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GUEST_LOGIN_FAILED, error })
    }
  }
}

export const CHECK_USER_TOKEN = createConst('CHECK_USER_TOKEN')
export const CHECK_USER_TOKEN_SUCCESS = createConst('CHECK_USER_TOKEN_SUCCESS')
export const CHECK_USER_TOKEN_FAILED = createConst('CHECK_USER_TOKEN_FAILED')
export const checkUserToken = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CHECK_USER_TOKEN })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/token/check',
        { token: userToken },
        userToken,
      )
      dispatch({ type: CHECK_USER_TOKEN_SUCCESS, response })
    } catch (error) {
      dispatch({ type: CHECK_USER_TOKEN_FAILED, error })
    }
  }
}

export const GET_USER_LOCATION = createConst('GET_USER_LOCATION')
export const GET_USER_LOCATION_SUCCESS = createConst('GET_USER_LOCATION_SUCCESS')
export const GET_USER_LOCATION_FAILED = createConst('GET_USER_LOCATION_FAILED')
export const DEFAULT_GET_USER_LOCATION_TIMEOUT = 10 * 1000 // in ms
export function getUserLocation(
  isApp = false,
  GET_USER_LOCATION_TIMEOUT = DEFAULT_GET_USER_LOCATION_TIMEOUT,
) {
  return async (dispatch, _, { getUserLocation }) => {
    dispatch({ type: GET_USER_LOCATION })

    try {
      const location = await Promise.race([
        getUserLocation(isApp),
        timeout(GET_USER_LOCATION_TIMEOUT),
      ])

      dispatch({ type: GET_USER_LOCATION_SUCCESS, location })
    } catch (error) {
      dispatch({ type: GET_USER_LOCATION_FAILED, error })
    }
  }
}

export const GET_USER_PERMISSION = createConst('GET_USER_PERMISSION')
export const GET_USER_PERMISSION_SUCCESS = createConst('GET_USER_PERMISSION_SUCCESS')
export const GET_USER_PERMISSION_FAILED = createConst('GET_USER_PERMISSION_FAILED')
export const getUserPermission = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_PERMISSION })
    const userToken = getUserToken(getState())
    const result = await fetchUserPermission(userToken)
    dispatch({ type: GET_USER_PERMISSION_SUCCESS, response: result.data })
    return result.data
  } catch (error) {
    dispatch({ type: GET_USER_PERMISSION_FAILED, error })
  }
}

export const validateUserFromLocalStorage = () => async (dispatch, _, { destroyCookie }) => {
  try {
    const userInfo = JSON.parse(window.localStorage.getItem('user.info'))
    if (userInfo?.token) {
      const { data } = await validateToken({ token: userInfo.token })
      if (data.code === 52000) {
        window.localStorage.removeItem('user.info')
        destroyCookie('user.info')
        window.localStorage.removeItem('user.token')
        destroyCookie('user.token')
        window.localStorage.removeItem('user.mid')
        destroyCookie('user.mid')
      } else {
        dispatch(setUser({ token: userInfo.token, userInfo }))
      }
    }
  } catch (error) {
    console.warn(error)
  }
}

export const SET_USER_INFO = createConst('SET_USER_INFO')
export function setUserInfo({ userInfo }) {
  return {
    type: SET_USER_INFO,
    userInfo,
  }
}

export const SET_MY_LOCATION = createConst('SET_MY_LOCATION')
export function setMyLocation({ coords }) {
  return {
    type: SET_MY_LOCATION,
    ...coords,
  }
}

export const CLEAR_USER_LOCATION = createConst('CLEAR_USER_LOCATION')
export function clearUserLocation() {
  return {
    type: CLEAR_USER_LOCATION,
  }
}

export const GET_USER_LOCATION_IN_APP = createConst('GET_USER_LOCATION_IN_APP')
export const GET_USER_LOCATION_IN_APP_SUCCESS = createConst('GET_USER_LOCATION_IN_APP_SUCCESS')
export const GET_USER_LOCATION_IN_APP_FAILED = createConst('GET_USER_LOCATION_IN_APP_FAILED')
export const getUserLocationInApp = () => async (
  dispatch,
  _getState,
  { appPermission, appPlatform, appGeolocation, appOneSignal },
) => {
  dispatch({
    type: GET_USER_LOCATION_IN_APP,
  })

  const requestPermission = appPlatform.select({
    ios: appPermission.PERMISSIONS.IOS.LOCATION_WHEN_IN_USE,
    android: appPermission.PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION,
  })
  const checkPermissionResult = await appPermission.check(requestPermission)
  if (checkPermissionResult !== 'granted' && checkPermissionResult !== 'denied') {
    dispatch({
      type: GET_USER_LOCATION_IN_APP_FAILED,
      errorMessage: 'NO_PERMISSION',
    })
    return
  }
  const requestPermissionResult = await appPermission.request(requestPermission)
  if (requestPermissionResult !== 'granted') {
    dispatch({
      type: GET_USER_LOCATION_IN_APP_FAILED,
      errorMessage: 'NO_PERMISSION',
    })
    return
  }
  appGeolocation.getCurrentPosition(
    ({ coords }) => {
      if (coords.latitude && coords.longitude) {
        appOneSignal.sendTags({
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
        dispatch({
          type: GET_USER_LOCATION_IN_APP_SUCCESS,
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
      } else {
        dispatch({
          type: GET_USER_LOCATION_IN_APP_FAILED,
          errorMessage: 'UNKNOWN_ERROR',
        })
      }
    },
    (error) => {
      dispatch({
        type: GET_USER_LOCATION_IN_APP_FAILED,
        errorMessage: 'UNKNOWN_ERROR',
        errorDetail: error,
      })
    },
    { enableHighAccuracy: true, timeout: 10000, maximumAge: 5000 },
  )
}

export const SET_USER_TOKEN = createConst('SET_USER_TOKEN')
export function setUserToken(token) {
  return {
    type: SET_USER_TOKEN,
    payload: token,
  }
}

export const CLEAR_USER_TOKEN = createConst('CLEAR_USER_TOKEN')
export function clearUserToken() {
  return { type: CLEAR_USER_TOKEN }
}

export const SET_USER = createConst('SET_USER')
export function setUser({ token = undefined, userInfo }) {
  return { type: SET_USER, payload: { token, userInfo } }
}
