import { openConfirmDialog } from '../app/actions'
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED'
export const LOGIN_REQUIRED_MESSAGE = 'LOGIN_REQUIRED_MESSAGE'

const auth = ({ i18n, pushRoute }) => ({ getState, dispatch }) => (next) => (action) => {
  const isLoginRequired = action[LOGIN_REQUIRED]
  const message = action[LOGIN_REQUIRED_MESSAGE] || i18n.t('common:此操作需要登入才能使用喔！')
  if (isLoginRequired) {
    const userRole = getState().currentUser.getIn(['userInfo', 'type'])
    if (userRole === 'guest') {
      return dispatch(
        openConfirmDialog({
          title: i18n.t('common:此操作請先登入會員'),
          content: message,
          onSure: () => {
            pushRoute('/login')
          },
        }),
      )
    }
  }
  return next(action)
}

export default auth
