import Immutable, { Map, List } from 'immutable'

import getSecretColor from './getSecretColor'

// old site Color
export const noImg =
  'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8++7DfwAJZwPMPd+20AAAAABJRU5ErkJggg=='

// redesign app no img color
export const redesignNoImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAKSURBVAjXY3gGAADoAOeNVZ+8AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAzLTE2VDA2OjU2OjAzKzAxOjAwLgU83AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMy0xNlQwNjo1NjowMyswMTowMF9YhGAAAAAASUVORK5CYII='

export const adMasonry = {
  _id: '5d22043a51546306ed5a2615',
  postID: 'ad.index',
  title: '<span style="color:rgb(237,238,240)">█████████</span>',
  text: '<span style="color:rgb(237,238,240)">████████████████████</span>',
  type: 'ad',
  ptype: 1,
  img: [
    {
      uid: 'pop.popdaily_AD',
      path: '',
      thumb: '',
      w: 800,
      h: 600,
      type: 'post',
      t: 1562510394732,
      _id: '5d22043a51546306ed5a2613',
    },
  ],
  clas: ['ad'],
  loc: null,
  ut: 1562510394796,
  touch: 200,
  newScore: 1537,
  doorsill: 1020,
  atouch: 0,
  like: 0,
  isLike: 0,
  saved: 0,
  view: 0,
  reply: 0,
  reply_no: 0,
  score: 1537,
  t: 1562510394796,
  trend: {
    slug: 'popsecretkimchi0707',
  },
  userInfo: {
    mid: '244',
    img: '',
    name: '<span style="color:rgb(237,238,240)">████</span>',
  },
}

// auth detect
export const detectPopdailyAccount = (mid) =>
  typeof mid === 'number' && ((mid > 241 && mid < 250) || mid == 345)

// web
export const webReplace = (post, maxLength = 500) => {
  if (post.web && post.web.text) {
    if (maxLength > -1) post.web.text = post.web.text.substr(0, maxLength) // from max-500 words
    post.web.text = post.web.text
      .replace(/<[^>]*>/g, '') // remove tag
      .replace(/\n/g, '') // remove break line
      .replace(/ /g, '') // remove space
      .replace(/[\u200B-\u200D\uFEFF\u3000]/g, '') // remove zwsp
      .substr(0, 100) // to min-100 words
    return post
  } else if (Array.isArray(post.newText)) {
    post.text = post.newText
      .filter((e) => e.type === 'paragraph' || e.type === 'quote')
      .map((e) => e.data.text)
      .join('')
      .replace(/<[^>]*>/g, '') // remove tag
      .replace(/\n/g, '') // remove break line
      .replace(/ /g, '') // remove space
      .replace(/[\u200B-\u200D\uFEFF\u3000]/g, '') // remove zwsp
      .substr(0, 100) // to min-100 words
    return post
  } else {
    return post
  }
} // *: if this webRplace happen twice↑ remeber move to libs.

// post fetch Title And Description
export const isType = (input, detect = '') => {
  const post = Map.isMap(input) ? input.toJS() : input
  return post && detect && post.type === detect
}

const isWebOrPugc = (post = {}) => {
  const { web = {} } = post
  return (
    (isType(post, 'web') || isType(post, 'pugc')) &&
    Object.keys(web).length !== 0 &&
    web.constructor === Object
  )
}

export const getPostTitle = (post = {}) => {
  const { title = '', web: { title: webTitle = '' } = {} } = post
  return isWebOrPugc(post) ? webTitle : title
}

export const getPostDescription = (post = {}) => {
  const { text = '', newText = [], web: { text: webText = '' } = {} } = post
  let description = ''
  // const time1 = new Date().getTime()
  if (newText.length > 0) {
    description = parserNewTextToHtml(newText)
  } else {
    description = isWebOrPugc(post) ? convertWebContent(webText) : convertContent(text)
    description = parserToHtml(description)
  }
  // const time2 = new Date().getTime()
  // console.log(`Call to convert method took ${(time2 - time1)} milliseconds.`, time2, time1)
  return description || ''
}

export const getPostTags = (postDescription) =>
  postDescription
    .split(/(\s|\n)/gim)
    .filter((val) => /^(#|＃)/g.test(val.trim()))
    .map((tag) => tag.replace(/^(#|＃)/g, '')) || []

export const getUsefulTags = (tagsSplit = [], title, description) => {
  if (tagsSplit.length) {
    return tagsSplit.filter((tag) => title.includes(tag)).filter((tag) => description.includes(tag))
  }
  return tagsSplit
}

export const getSEODescription = (postTitle, postDescription) => {
  let SEODescription = postDescription
  // replace unused char
  const description = postDescription
    .replace(/<[^>]+>/g, '')
    .replace(/\r\n/g, ' ')
    .replace(/\n/g, ' ')
    .replace(/\s/g, ' ')
  SEODescription = description
  // get tag array: split hashtag words and remove #|＃
  const tagsSplit = getPostTags(description)
  // filter unused tag in post
  let postTag = getUsefulTags(tagsSplit, postTitle, description)
  if (postTag.length > 0) {
    // get pure description context without symbol.
    const pureDescriptionContext = parserToContext(postDescription).replace(
      /(&nbsp;|[~!@#$%^&*()_+=\-`[\]{}';:".,<>/?|「」《》])/gi,
      '',
    )
    SEODescription = pureDescriptionContext // postDescription
    // Build SEO Description
    const tagIndex = postTag
      .map((tag) => pureDescriptionContext.indexOf(tag))
      .filter((index) => index !== -1)
    if (tagIndex.length > 0) {
      let startIndex = tagIndex[0] - 20
      let endIndex = startIndex + 100
      if (startIndex < 0) startIndex = 0
      if (endIndex > pureDescriptionContext.length) endIndex = pureDescriptionContext.length
      // fetch 120 char start from (tagindex - 20) to (tagindex + 100)
      SEODescription = pureDescriptionContext.substr(startIndex, endIndex)
    }
    // concat and remove duplicates tag
    postTag = postTag.concat(tagsSplit).filter((tag, pos, arr) => arr.indexOf(tag) === pos)
    // limit tags length is 5
    if (postTag.length > 5) postTag.length = 5
    const postTagString = postTag.map((tag) => `#${tag}`).join(' ')
    return `${postTagString}...${SEODescription.substr(0, 117 - postTagString.length)}`
  }
  // limit tags length is 5
  if (tagsSplit.length > 5) tagsSplit.length = 5
  const tagsString = tagsSplit.map((tag) => `#${tag}`).join(' ')
  return `${tagsString}...${SEODescription.substr(0, 117 - tagsString.length)}`
}

// clas
export const hasClas = (clas = [], whiteList = []) => {
  const clasJS = List.isList(clas) ? clas.toJS() : clas
  let result = false
  whiteList.forEach((allow) => (result = result || clasJS.includes(allow)))
  return result
}

// clas detect
export const isSecretClas = (clas = []) => hasClas(clas, ['secret'])

// secretcolor
export const createSecretColor = (articles = null) => {
  const isArray = Array.isArray(articles)
  if (isArray) {
    return articles.map((article) => {
      article.secretColor = getSecretColor()
      return article
    })
  }

  const isObj = !isArray && typeof articles === 'object' && articles !== null
  if (isObj) {
    articles.secretColor = getSecretColor()
  }

  return articles
}

// number [1,000] as [1K]
export const convertNumToStr = (num = 0, min = 10000) => {
  if (num <= 0) return 0
  if (num < min || num < 1000) return num
  const prefix = ['k', 'M', 'G', 'T', 'P']
  const pow = Math.floor(Math.log(num) / Math.log(1000))
  return (num / Math.pow(1000, pow)).toFixed() + prefix[pow - 1]
}

// text content
export const parserToContext = (html, substr = null) => {
  const result = html
    .replace(/<[^>]+>/g, '')
    .replace(/\r\n/g, '')
    .replace(/\n/g, '')
  return result.substr(0, substr || result.length)
}

export const parserToHtml = (html) => {
  if (typeof document !== 'undefined') {
    const fakeDom = document.createElement('div')
    fakeDom.innerHTML = `<div>${html.trim()}</div>`
    const div = fakeDom.firstChild
    return div.innerHTML
  } else {
    return html.substr(0, 800)
  }
}

export const parserNewTextToHtml = (newText = []) => {
  let newHtml = ''
  const formatNewText = (type, data) => {
    let newStr = ''
    let imgAlt = ''
    switch (type) {
      case 'header': {
        const headerLevel = parseInt(data.level, 10) >= 5 ? 6 : parseInt(data.level, 10) + 1
        newStr = `<h${headerLevel}>${data.text}</h${headerLevel}>`
        break
      }
      case 'image': {
        if (data.file) {
          imgAlt =
            data.caption ||
            (typeof document !== 'undefined' ? document.title.split('｜')[0] : '') ||
            ''
          const widthAttribute = data.file.w ? `width="${data.file.w}"` : ''
          const heightAttribute = data.file.h ? `height="${data.file.h}"` : ''
          newStr = `<img src="${data.file.url}" alt="${imgAlt}" ${widthAttribute} ${heightAttribute}/>`
        } else {
          newStr = ''
        }
        break
      }
      case 'embed': {
        switch (data.service) {
          case 'skyscanner': {
            let iFrameHeight = '203px'
            if (data.embed.indexOf('indicative_price') === -1) {
              iFrameHeight = '302px'
            }
            newStr = `<div style="height: ${iFrameHeight}" class="skiframContent"><iframe src="${data.embed}" /></div>`
            break
          }
          case 'facebook': {
            newStr = `<div class="fbiframContent" data-src="${data.source}"><iframe class="facebook-media" src="${data.embed}" /></div>`
            break
          }
          case 'instagram': {
            newStr = `<div class="igiframContent" data-src="${data.source}"><iframe class="instagram-media" src="${data.embed}" /></div>`
            break
          }
          case 'youtube': {
            newStr = `<div class="ytiframContent"><iframe src="${data.embed}"></iframe></div>`
            break
          }
        }
        break
      }
      case 'list': {
        if (data.items && data.items.length > 0) {
          newStr = `<ul class=${data.style.toLowerCase() === 'ordered' ? 'ulNum' : 'ulDisc'}>`
          for (let i = 0; i < data.items.length; i++) {
            newStr += `<li>${data.items[i]}</li>`
          }
          newStr += '</ul>'
        }
        break
      }
      case 'delimiter': {
        newStr = '<div class="delimiter"></div>'
        break
      }
      case 'paragraph': {
        newStr = `<p>${data.text}</p>`
        if (data.text === '') {
          newStr = '<br>'
        }
        break
      }
      case 'quote': {
        newStr = `<div class="quote">${data.text}</div>`
        break
      }
      // case 'checklist': {
      //   break
      // }
      default: {
        break
      }
    }
    return newStr
  }
  for (let i = 0; i < newText.length; i++) {
    newHtml += formatNewText(newText[i].type, newText[i].data)
  }
  return newHtml
}

// text content convert to link
export const convertContent = (text = '') => {
  let __html = decodeURIComponent(encodeURIComponent(text))
  __html = __html.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />')
  __html = __html.replace(
    /https:\/\/www\.popdaily\.com\.tw\/wp-content\/uploads\//g,
    'https://static.popdaily.com.tw/wp-content/uploads/',
  )
  return __html
}

// web text content convert to link
export const convertWebContent = (text = '') => {
  let __html = decodeURIComponent(encodeURIComponent(text))
  __html = __html.replace(
    /<script async defer src="\/\/www.instagram.com\/embed.js"><\/script>/g,
    '',
  )
  __html = __html.replace(
    /https:\/\/static\.popdaily\.com\.tw\/article/g,
    'https://www.popdaily.com.tw/article',
  )
  return __html
}

// comments list/like list convert to list and support topComment
export const convertComments = (list = [], likes = [], topComment = -1) => {
  // merge like list
  const likeCheck = likes.map((likeReply) => likeReply.replyID) //.filter((e, i, self) => self.indexOf(e) === i)
  likeCheck.forEach((replyID) => {
    let index = list.findIndex((reply) => reply.replyID == replyID)
    if (list[index]) list[index].isLike = true
  })

  // render new comments
  const newComments = []
  const mainComments = list.reverse().filter((reply) => reply.b === 0)
  const commentReply = list.filter((reply) => reply.b !== 0) // .reverse() or not
  // console.log(comments.length, mainComments.length, commentReply.length)

  const replyCheck = commentReply
    .map((reply) => reply.b)
    .filter((e, i, self) => self.indexOf(e) === i)
  replyCheck.forEach((e) => {
    const index = mainComments.findIndex((reply) => reply.replyID == e)
    if (mainComments[index]) mainComments[index].hasReply = true
  })

  // search and move to top - start
  if (topComment > -1) {
    let topReply = null
    const topCommentReplyIndex = commentReply.findIndex((reply) => reply.replyID === topComment)
    if (topCommentReplyIndex > -1) topReply = commentReply.splice(topCommentReplyIndex, 1)[0]

    const topMainCommentIndex = mainComments.findIndex((reply) => reply.replyID === topComment)
    if (topMainCommentIndex > -1) topReply = mainComments.splice(topMainCommentIndex, 1)[0]

    topReply && newComments.push(topReply)
  }
  // search and move to top - end

  const topTotal = mainComments.length
  for (let i = 0; i < topTotal; i++) {
    const mainReply = mainComments.shift()
    newComments.push(mainReply)

    const searchReplyRules = (reply) => reply.b === mainReply.replyID

    if (mainReply.hasReply) {
      const searchResultReply = commentReply.filter(searchReplyRules).reverse()
      const resultTotal = searchResultReply.length

      for (let j = 0; j < resultTotal; j++) {
        const reply = searchResultReply.shift()
        newComments.push(reply)
      }
    }
  }
  // Find diff comments for debug use, don't remove.
  // Array.prototype.diff = function(a) {
  //   return this.filter((i) => { return a.indexOf(i) < 0 })
  // }
  // console.log(comments.diff(newComments))
  return newComments
}

// handle list like update
const updateLike = (list, like) =>
  list.set('isLike', like ? 1 : 0).set('like', list.get('like', 0) + (like ? 1 : -1))

export const listLikeUpdate =
  ({ postID = '', like = 0 }) =>
  (lists) => {
    if (!lists) {
      return Immutable.fromJS([])
    }
    return lists.map((list) => (list.get('postID') === postID ? updateLike(list, like) : list))
  }

export const commentsLikeUpdate =
  ({ postID = '', replyID = 0, like = 0 }) =>
  (lists) => {
    return lists.map((list) =>
      list.get('postID') === postID && list.get('replyID') === replyID
        ? updateLike(list, like)
        : list,
    )
  }
