import axios from 'axios'

export const v2 = axios.create({
  baseURL: '/api/v2',
})

export const setV2BaseUrl = (url) => {
  v2.defaults.baseURL = url
}

export const applyErrorHandleInterceptor = () => {
  const handleResponse = (response) => response
  const handleError = (error) => {
    let errorResult
    if (error.response) {
      errorResult = error.response
    } else if (error.request) {
      errorResult = error.request
    } else {
      errorResult = error.message
    }

    if (
      errorResult &&
      errorResult.data &&
      errorResult.data.errors &&
      errorResult.data.errors.length > 0 &&
      errorResult.status
    ) {
      return {
        errorCodes: errorResult.data.errors.map((error) => error.code),
        status: errorResult.status,
      }
    } else if (errorResult && errorResult.status) {
      return {
        errorCodes: ['httpStatusCodeOnly'],
        status: errorResult.status,
      }
    } else {
      return {
        errorCodes: ['unexpected'],
      }
    }
  }

  v2.interceptors.response.use(handleResponse, handleError)
}

export const uploadImage = ({ body, config = undefined }) => {
  return v2.post('/images', body, config)
}

export const editImage = ({ id, body, config }) => {
  return v2.put(`/images/${id}`, body, config)
}

export const deleteImage = ({ id }) => {
  return v2.delete(`/images/${id}`)
}

export const getImage = ({ id }) => {
  return v2.get(`/images/${id}`)
}

/**
 * example response.data :
{
  "name": "test user",
  "img": "https://example.com/image.jpg",
  "mid": "123456",
  "uid": "fb.123456",
  "followerCount": 100,
  "agreedPolicies": false
}
*/
export const fetchCurrentUser = () => {
  return v2.get('/users/me')
}

export const updateCurrentUser = ({ user }) => {
  return v2.put('/users/me', user)
}

export const fetchDailyModule = () => {
  return v2.get('/daily')
}
