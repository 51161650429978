import { Map } from 'immutable'

import { isType, getPostTitle, getPostDescription } from '../posts/postTools'

const mapToJS = (post) => (Map.isMap(post) ? post.toJS() : post)

export const trackingShareProperty = (post, extProperties = {}) => {
  let newProperties = {}
  if (isType(post, 'pugc') || isType(post, 'web') || isType(post, 'user')) {
    newProperties = postTrackingProperties(post)
  }
  if (isType(post, 'place')) newProperties = placeTrackingProperties(post)
  if (isType(post, 'popcoupon')) newProperties = couponTrackingProperties(post)
  return Object.assign(newProperties, extProperties)
}

// https://docs.google.com/spreadsheets/d/19q-iJteJhL8cAznScqEWT-qZxuooZahIMHM30_N6GiA/edit#gid=1657637709
export const postTrackingProperties = (post) => {
  const postJSON = mapToJS(post)
  const { loc = null, postID, clas: category, type: postType, userInfo = {}, img = [] } = postJSON
  const { mid = '', name = '' } = userInfo
  const result = { type: 'post' }
  result['post.id'] = postID
  result['post.category'] = category
  result['post.type'] = postType
  // result['post.author.uid'] // how to get?
  result['post.author.mid'] = mid
  result['post.author.name'] = name
  // result['post.author.role'] // how to get?
  const title = getPostTitle(postJSON)
  result['post.title'] = title
  const description = getPostDescription(postJSON)
  result['post.text'] = description
  result['post.text.characters'] = description.length
  result['post.checkin.location'] = loc
  result['post.imgage'] = img.length
  return result
}

export const placeTrackingProperties = (place) => {
  const result = { type: 'place' }
  const {
    postID,
    clas: mainCategory,
    place: { choice = 0, name, city, priceRange: price, tag: subCategory },
  } = mapToJS(place)
  result['place.id'] = postID
  result['place.type'] = choice === 1 ? 'choice' : 'ghost'
  result['place.name'] = name
  result['place.price'] = price
  result['place.city'] = city
  result['place.main.category'] = mainCategory
  result['place.sub.category'] = subCategory
  return result
}

export const couponTrackingProperties = (gift) => {
  const result = { type: 'popcoupon' }
  const { postID, type, giftID, name, redeemed, stock } = mapToJS(gift)
  result['gift.id'] = type || 'popcoupon'
  result['gift.type'] = `git.${giftID}`
  result['place.id'] = postID
  result['event.id'] = ''
  result['gift.title'] = name
  result['gift.redeemed'] = redeemed || 0
  result['gift.quota'] = stock || 0
  result['gift.total'] = (stock || 0) + (redeemed || 0)

  return result
}
