const secretColors = [
  '#efc5e1',
  '#ead3ef',
  '#e6dcf4',
  '#f2dde0',
  '#efc5c5',
  '#e5cccc',
  '#e8dec1',
  '#f2e8da',
  '#f4ebe6',
  '#f5f2eb',
  '#d1c6aa',
  '#efefef',
  '#cab9b5',
  '#e5cebd',
  '#d8d2c3',
  '#a8c6b9',
  '#c2cece',
  '#d7e0bd',
  '#d6ddce',
  '#e3e4e3',
  '#e2e1dd',
  '#e7edf2',
]

const getSecretColor = () => {
  return secretColors[Math.floor(Math.random() * secretColors.length)]
}

export default getSecretColor
