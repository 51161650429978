import isEmpty from 'lodash/isEmpty'
import { v1GetWithToken, v1PostWithToken } from '../api/v1'
import { trackingShareProperty } from '../beacon/beacon'
import { setUserInfo } from '../currentUser/actions'
import { getUserToken } from '../currentUser/selector'
import { GET_LIST_SUCCESS } from '../libs/ApiResponseCode'
import constantCreator from '../libs/constantCreator'
import { LOGIN_REQUIRED } from '../middlewares/auth'
import { DEFAULT_SCORE, SUCCESS_TOPIC_NEW } from './constants'
import { getNextPageForPostList } from './utils'

const createConst = constantCreator('posts')

export const RESET_LISTS = createConst('RESET_LISTS')
export const resetLists = () => ({ type: RESET_LISTS })

export const RESET_POSTS = createConst('RESET_POSTS')
export const resetPosts = () => ({ type: RESET_POSTS })

export const SET_TYPE = createConst('SET_TYPE')
export const setType = (payload = { type: 'explore' }) => ({ type: SET_TYPE, payload })

export const SET_CLAS = createConst('SET_CLAS')
export const setClas = (payload = { clas: 'featured' }) => ({ type: SET_CLAS, payload })

export const SET_MODE = createConst('SET_MODE')
export const setMode = (payload = { mode: 'new' }) => ({ type: SET_MODE, payload })

export const POSTS_ARTICLE_LOADED = createConst('POSTS_ARTICLE_LOADED')
export const postsArticleLoaded = (payload = { index: 0 }) => ({
  type: POSTS_ARTICLE_LOADED,
  payload,
})

export const GET_POST_LIST = createConst('GET_POST_LIST')
export const GET_POST_LIST_SUCCESS = createConst('GET_POST_LIST_SUCCESS')
export const GET_POST_LIST_FAILED = createConst('GET_POST_LIST_FAILED')
export const getIndexPostList = ({
  type = '',
  clas = '',
  mode = '',
  page = -1,
  score = 99999,
  param = '',
  touch = [],
}) => {
  return async (dispatch, _getState, { isLocal, getDevicePrefixName }) => {
    const defaultPage = mode === 'hot' ? 0 : 9999999999999
    const options = {
      isLocal,
      isApp: getDevicePrefixName() === 'A',
      page,
    }
    dispatch({ type: GET_POST_LIST, ...options })
    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/list/post',
        {
          type,
          clas,
          mode,
          page: page !== -1 ? page : defaultPage,
          score,
          param,
          touch,
        },
        userToken,
      )
      dispatch({ type: GET_POST_LIST_SUCCESS, response, ...options })
    } catch (error) {
      dispatch({ type: GET_POST_LIST_FAILED, error, ...options })
    }
  }
}

export const getInitialPostList =
  ({
    type = '',
    clas = '',
    mode = '',
    page = -1,
    score = 99999,
    param = '',
    touch = [],
    paginationIndex = 1,
  }) =>
  async (dispatch, getState) => {
    try {
      const defaultPage = mode === 'hot' ? 0 : 9999999999999
      const data = {
        type,
        clas,
        mode,
        page: page !== -1 ? page : defaultPage,
        score,
        param,
        touch,
      }
      const url = '/list/post'
      dispatch({ type: GET_POST_LIST })

      const userToken = getUserToken(getState())
      let res = await v1PostWithToken(url, data, userToken)

      if (res.data.list.length === 0) {
        return dispatch({
          type: GET_POST_LIST_FAILED,
          response: { msg: 'No Posts', code: res.data.code },
        })
      }

      //(paginationIndex - 1) more requests for getting Page paginationIndex
      for (let i = 0; i < paginationIndex - 1; i++) {
        data.page = res.data.list[res.data.list.length - 1].t
        data.touch = res.data.list.map((post) => ({
          postID: post.postID,
          t: post.t,
        }))
        data.score = res.data.list && Math.min(...res.data.list.map((el) => el.score))
        const nextRes = await v1PostWithToken(url, data, userToken)

        if (nextRes.data.list.length === 0) {
          break
        } else {
          res = nextRes
        }
      }

      return dispatch({ type: GET_POST_LIST_SUCCESS, response: res.data })
    } catch (error) {
      return dispatch({
        type: GET_POST_LIST_FAILED,
        response: { msg: 'error in action creator (getInitialPostList)' },
      })
    }
  }

export const getPostList =
  ({ type = '', clas = '', mode = '', page: pageFromAction, score = DEFAULT_SCORE }) =>
  async (dispatch, getState, { isLocal, getDevicePrefixName }) => {
    const page = getNextPageForPostList({
      fromAction: pageFromAction,
      fromStore: getState().posts.getIn(['posts', 'page']),
      mode,
    })
    dispatch({ type: GET_POST_LIST, payload: { type, clas, mode, page, score } })
    const userToken = getUserToken(getState())
    if (isEmpty(userToken)) throw new Error('Cannot make API requests without userToken')
    try {
      const isApp = [
        'A',
        false, // = server env.; so that it won't inject ads
      ].includes(getDevicePrefixName())
      const { data: response } = await v1PostWithToken(
        '/list/post',
        { type, clas, mode, page, score },
        userToken,
      )
      const action = {
        type: response.code === GET_LIST_SUCCESS ? GET_POST_LIST_SUCCESS : GET_POST_LIST_FAILED,
        isLocal,
        isApp,
        response,
      }
      dispatch(action)
    } catch (error) {
      dispatch({ type: GET_POST_LIST_FAILED, response: { msg: error.message } })
    }
  }

export const GET_INDEX_CAROUSEL = createConst('GET_INDEX_CAROUSEL')
export const GET_INDEX_CAROUSEL_SUCCESS = createConst('GET_INDEX_CAROUSEL_SUCCESS')
export const GET_INDEX_CAROUSEL_FAILED = createConst('GET_INDEX_CAROUSEL_FAILED')
export const getIndexCarousel = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_INDEX_CAROUSEL })
    try {
      const userToken = getUserToken(getState())
      const reqBody = {
        type: 'website',
        clas: 'featured',
        page: 9999999999999,
        score: 99999,
        urlParam: {
          limit: 10,
          slider: true,
        },
      }
      const { data: response } = await v1PostWithToken('/list/post', reqBody, userToken)
      dispatch({ type: GET_INDEX_CAROUSEL_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_INDEX_CAROUSEL_FAILED, error })
    }
  }
}

export const POST_LIKE = createConst('POST_LIKE')
export const POST_LIKE_SUCCESS = createConst('POST_LIKE_SUCCESS')
export const POST_LIKE_SUCCESS_DONE = createConst('POST_LIKE_SUCCESS_DONE')
export const POST_REPLY_LIKE_SUCCESS_DONE = createConst('POST_REPLY_LIKE_SUCCESS_DONE')
export const POST_LIKE_ERROR = createConst('POST_LIKE_SUCCESS_ERROR')
export const postLike = ({ postID = '', replyID = 0, isLike: like, via, data = {} }) => {
  return async (dispatch, getState, { getRouteName }) => {
    const url = `/${replyID > 0 ? 'reply' : 'post'}/like`
    const body = { postID, like }
    if (replyID > 0) body.replyID = replyID
    const category = replyID > 0 ? 'comment' : 'article'
    const loveOrNotLove = like ? 'love' : 'unlove'
    const viaRouteName = getRouteName()

    const options = {
      beacon: {
        properties: {
          eventCategory: category,
          eventAction: loveOrNotLove,
          eventLabel: postID,
          ...trackingShareProperty(data),
          category,
          postID,
          replyID: replyID > 0 ? replyID : undefined,
          like,
          via: via || viaRouteName,
        },
      },
    }

    dispatch({ type: POST_LIKE, ...options })
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(url, body, userToken)
      dispatch({ type: POST_LIKE_SUCCESS, response, ...options })

      const type = replyID > 0 ? POST_REPLY_LIKE_SUCCESS_DONE : POST_LIKE_SUCCESS_DONE
      dispatch(postLikeDone(type, like, postID, replyID, response))
      return { postID, replyID, like }
    } catch (error) {
      dispatch(postLikeFail(error))
      throw error
    }
  }
}
export function postLikeDone(type, like, postID, replyID, response) {
  return { type, payload: { postID, replyID, like }, response }
}
export function postLikeFail(error) {
  return { type: POST_LIKE_ERROR, payload: { error } }
}

export const SET_POST_TO_TOPIC = createConst('SET_POST_TO_TOPIC')
export const SET_POST_TO_TOPIC_SUCCESS = createConst('SET_POST_TO_TOPIC_SUCCESS')
export const SET_POST_TO_TOPIC_FAILED = createConst('SET_POST_TO_TOPIC_FAILED')
export function setPostToTopic({ postId, topics }) {
  return async (dispatch, _getState) => {
    const body = {
      postID: postId,
      // topics 是一個 array 格式如下
      // topics: [
      //   {
      //     topicID2: topic ? topic.get('postID') : '',
      //     d: add ? 0 : 1, // 0 for adding, 1 for removing
      //   }
      // ],
      topics,
    }
    const options = {
      [LOGIN_REQUIRED]: true,
      topics,
      postId,
    }
    dispatch({ type: SET_POST_TO_TOPIC, ...options })
    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken('/topic/addPost', body, userToken)
      dispatch({ type: SET_POST_TO_TOPIC_SUCCESS, response, ...options })
      dispatch(getTopicList({ postId }))
    } catch (error) {
      dispatch({ type: SET_POST_TO_TOPIC_FAILED, error, ...options })
    }
  }
}

export const GET_TOPIC_LIST = createConst('GET_TOPIC_LIST')
export const GET_TOPIC_LIST_SUCCESS = createConst('GET_TOPIC_LIST_SUCCESS')
export const GET_TOPIC_LIST_FAILED = createConst('GET_TOPIC_LIST_FAILED')
export const getTopicList =
  ({ postId }) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_TOPIC_LIST })
    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken(
        '/topic/getList',
        { postID: postId },
        userToken,
      )
      dispatch({ type: GET_TOPIC_LIST_SUCCESS, response, postId })
    } catch (error) {
      dispatch({ type: GET_TOPIC_LIST_FAILED, error })
    }
  }

export const Add_TOPIC = createConst('ADD_TOPIC')
export const ADD_TOPIC_SUCCESS = createConst('ADD_TOPIC_SUCCESS')
export const ADD_TOPIC_FAILED = createConst('ADD_TOPIC_FAILED')
export const addTopic = ({ formData, postId }) => {
  return async (dispatch, _getState) => {
    try {
      dispatch({ type: Add_TOPIC })
      const userToken = getUserToken(_getState())
      const response = await v1PostWithToken('/topic/addTopic', formData, userToken)
      if (response.data.code === SUCCESS_TOPIC_NEW && response.data.data) {
        const { topicID2: topicId } = response.data.data
        await dispatch(getTopicList({ postId }))
        dispatch({ type: ADD_TOPIC_SUCCESS, response })
        return topicId
      }
    } catch (error) {
      dispatch({ type: ADD_TOPIC_FAILED, error: error.message })
    }
  }
}
export const SET_TOPIC_LIST = createConst('SET_TOPIC_LIST')
export const setTopicList = ({ topicList, postId }) => {
  return {
    topicList,
    postId,
    type: SET_TOPIC_LIST,
  }
}

export const GET_TRACKING_POST_LIST = createConst('GET_TRACKING_POST_LIST')
export const GET_TRACKING_POST_LIST_SUCCESS = createConst('GET_TRACKING_POST_LIST_SUCCESS')
export function loadFollowingPosts({ userID, isRefresh = true }) {
  return async (dispatch, getState) => {
    try {
      const type = 'following'
      const defaultPage = 9999999999999
      const { posts } = getState()
      let page = isRefresh ? defaultPage : posts.getIn(['followerPosts', 'page'])
      let hasMore = posts.getIn(['followerPosts', 'hasMore'])
      if (!hasMore && !isRefresh) {
        return
      }
      dispatch({
        type: GET_TRACKING_POST_LIST,
      })
      if (!userID.includes('guest')) {
        const userToken = getUserToken(getState())
        const currentUser = await v1PostWithToken('/user/get', { umid: userID }, userToken)
        dispatch(
          // update topic list
          setUserInfo({
            userInfo: {
              ...currentUser.data,
              token: userToken,
              userToken,
            },
          }),
        )
      }

      const getMyFollowingPosts = async (nextPage) => {
        const userToken = getUserToken(getState())
        const res = await v1PostWithToken(
          '/list/post',
          {
            type,
            page: nextPage || (page !== -1 ? page : defaultPage),
          },
          userToken,
        )
        return res.data
      }

      const trackingPosts = await getMyFollowingPosts()
      const { nextPage, list } = trackingPosts
      page = nextPage || (list && list.length > 0 && list[list.length - 1].t) || defaultPage
      hasMore = list && list.length >= 7

      if (hasMore && list.length < 8) {
        const trackingPostsMore = await getMyFollowingPosts(page)
        const { nextPage, list } = trackingPostsMore
        trackingPosts.list = trackingPosts.list.concat(list)
        page = nextPage || (list.length > 0 && list[list.length - 1].t) || defaultPage
        hasMore = list.length >= 7
      }

      dispatch({
        type: GET_TRACKING_POST_LIST_SUCCESS,
        ...trackingPosts,
        page,
        hasMore,
        isRefresh,
      })
    } catch (error) {
      console.warn(error)
    }
  }
}

export const GET_RECOMMEND_USERS = createConst('GET_RECOMMEND_USERS')
export const GET_RECOMMEND_USERS_SUCCESS = createConst('GET_RECOMMEND_USERS_SUCCESS')
export function loadRecommendUsers() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_RECOMMEND_USERS,
      })
      const defaultPage = 9999999999999
      const userToken = getUserToken(getState())
      const recommendUsersRes = await v1GetWithToken(
        '/users/recommended_followees',
        {
          exclude_followed: true,
          limit: 8,
        },
        userToken,
      )

      const accounts = recommendUsersRes.data.recommendedFollowees
      const postRequestPromises = []
      for (let i = 0; i < accounts.length; i++) {
        const mid = accounts[i].mid
        const promisePostRequest = async () => {
          const postRes = await v1PostWithToken(
            'list/post',
            {
              type: 'user',
              clas: mid,
              page: defaultPage,
            },
            userToken,
          )
          if (postRes.data.error !== undefined) {
            accounts[i].postList = []
          } else {
            accounts[i].postList = postRes.data.list.slice(0, 3)
          }
          if (accounts[i].postList.length < 3) {
            const iterateEmptyLength = 3 - accounts[i].postList.length
            for (let j = 0; j < iterateEmptyLength; j++) {
              accounts[i].postList.push({
                _id: `${mid}${j}`,
                type: 'empty',
              })
            }
          }
          return accounts[i].postList
        }
        postRequestPromises.push(promisePostRequest())
      }

      await Promise.all(postRequestPromises)

      const recommendAccounts = accounts.reduce((acc, cur) => {
        acc.push({
          id: cur.mid,
          type: 'account',
          userInfo: cur,
          postCount: cur.postCount,
          isFollowed: cur.isFollowed,
          postList: cur.postList,
        })
        return acc
      }, [])

      dispatch({
        type: GET_RECOMMEND_USERS_SUCCESS,
        list: recommendAccounts,
      })
    } catch (error) {
      console.warn(error)
    }
  }
}

export const GET_RECOMMEND_POST_LIST = createConst('GET_RECOMMEND_POST_LIST')
export const GET_RECOMMEND_POST_LIST_SUCCESS = createConst('GET_RECOMMEND_POST_LIST_SUCCESS')
export function loadRecommendPosts({ isRefresh = true }) {
  return async (dispatch, getState) => {
    try {
      const defaultPage = 9999999999999
      const { posts } = getState()
      let page = isRefresh ? defaultPage : posts.getIn(['recommendPosts', 'page'])
      let hasMore = posts.getIn(['recommendPosts', 'hasMore'])
      if (!hasMore && !isRefresh) {
        return
      }
      dispatch({
        type: GET_RECOMMEND_POST_LIST,
      })

      const type = 'explore'
      const clas = 'hot'
      const getRecommendPosts = async (nextPage) => {
        const userToken = getUserToken(getState())
        const res = await v1PostWithToken(
          '/list/post',
          {
            type,
            clas,
            page: nextPage || (page !== -1 ? page : defaultPage),
          },
          userToken,
        )
        return res.data
      }

      const recommendPosts = await getRecommendPosts()
      const { nextPage, list } = recommendPosts
      page = nextPage || (list.length > 0 && list[list.length - 1].t) || defaultPage
      hasMore = list.length >= 7

      if (hasMore && list.length < 8) {
        const trackingPostsMore = await getRecommendPosts(page)
        const { nextPage, list } = trackingPostsMore
        recommendPosts.list = recommendPosts.list.concat(list)
        page = nextPage || (list.length > 0 && list[list.length - 1].t) || defaultPage
        hasMore = list.length >= 7
      }

      dispatch({
        type: GET_RECOMMEND_POST_LIST_SUCCESS,
        ...recommendPosts,
        page,
        hasMore,
        isRefresh,
      })
    } catch (error) {
      console.warn(error)
    }
  }
}

function updateFollowStatusInRecommendAccounts({ posts, followID, follow, dispatch }) {
  const recommendAccounts = posts.getIn(['recommendAccounts', 'list']).toArray()
  const newRecommendAccounts = []
  recommendAccounts.forEach((element) => {
    const account = element.toJS()
    const mid = account.userInfo.mid
    if (mid === followID) {
      account.isFollowed = follow
    }
    newRecommendAccounts.push(account)
  })
  dispatch({
    type: UPDATE_RECOMMEND_USERS,
    list: newRecommendAccounts,
  })
}

export const UPDATE_RECOMMEND_USERS = createConst('UPDATE_RECOMMEND_USERS')
export function followUser({ followID, follow }) {
  return async (dispatch, getState) => {
    const { posts } = getState()

    // update follow status for UI immediately
    updateFollowStatusInRecommendAccounts({ posts, followID, follow, dispatch })

    const userToken = getUserToken(getState())
    const res = await v1PostWithToken('/user/follow', { followID, follow }, userToken)

    if (res.status !== 200) {
      // recover follow status for UI
      updateFollowStatusInRecommendAccounts({ posts, followID, follow: !follow, dispatch })
    }
  }
}

export const SAVE_ARTICLE_TO_TOPIC = createConst('SAVE_ARTICLE_TO_TOPIC')
export function saveArticleToTopic({ postID, topicID }) {
  return {
    type: SAVE_ARTICLE_TO_TOPIC,
    postID,
    topicID,
  }
}

export const UNSAVE_ARTICLE_TO_TOPIC = createConst('UNSAVE_ARTICLE_TO_TOPIC')
export function unsaveArticleToTopic({ postID, topicID }) {
  return {
    type: UNSAVE_ARTICLE_TO_TOPIC,
    postID,
    topicID,
  }
}

export const COMMENT_ARTICLE = createConst('COMMENT_ARTICLE')
export function commentArticle({ postID }) {
  return {
    type: COMMENT_ARTICLE,
    postID,
  }
}
